// ページ内アンカーリンクのスクロールエフェクト
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定)
jQuery(function($) {
    var headerHeight = 0; // ヘッダー追従でない場合
	var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合
    $('a[href^="#"]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        if ( target.length > 0 ) {
            var position = target.offset().top-headerHeight/2;
            $("html, body").animate({scrollTop:position}, 550, "swing");
            return false;
        }
    });
})

// ブログサムネイル 中心でトリミング
jQuery(function($) {
    if ( $('.obj_image').length > 0 ) {
        objectFitImages('.obj_image');
    }
});


// ページトップボタンの表示
// jQuery(function($) {
	
// 	if ( $('#pageup').length > 0 ) {
// 		var topBtn = $('#pageup');    
// 		topBtn.css("display", "none");
// 		//スクロールが100に達したらボタン表示
// 		$(window).scroll(function () {
// 			if ($(this).scrollTop() > 100) {
// 				topBtn.fadeIn();
// 			} else {
// 				topBtn.fadeOut();
// 			}
// 		});
// 		//スクロールしてトップ
// 		topBtn.click(function () {
// 			$('body,html').animate({
// 				scrollTop: 0
// 			}, 500);
// 			return false;
// 		});
// 	}
// });


// ヘッダーの固定エフェクト
// jQuery(function($) {
// 	var $header = $('header');
// 	$(window).scroll(function() {
// 		if ($(window).scrollTop() > 50) {
// 			$header.addClass('fixed');
// 		} else {
// 			$header.removeClass('fixed');
// 		}
// 	});
// });



// ドロワーメニュー
jQuery(function($) {
    if ($('#toggle').length > 0) {
		$('#toggle').click(function () {
			$(this).toggleClass('toggle_active');
			$('#drawer_layer').fadeToggle();
			$('body').toggleClass('drawer_open');
			
			if ($(this).hasClass('toggle_active')) {
				$('nav').addClass('is_open');
				$('nav').removeClass('is_close');
			} else {
				$('nav').removeClass('is_open');
				$('nav').addClass('is_close');
			}
		});
		
		$('#drawer_layer').click(function () {
			$(this).fadeOut();
			$('#toggle').removeClass('toggle_active');
			$('body').removeClass('drawer_open');
			$('nav').removeClass('is_open');
			$('nav').addClass('is_close');
		});
		
		// エリア内リンク用
		$('nav a').on('click', function(){
			// if (window.innerWidth <= 768) {
			if ($('#toggle:visible').length > 0) {
				$('#toggle').click();
			}
		});
	}
    
});

// メニューの現在地ハイライト
jQuery(function($) {
	// if (window.matchMedia('(min-width: 768px)').matches) {
		//基準点の準備
		var elemTop = [];

		//現在地を取得するための設定を関数でまとめる
		function PositionCheck(){
			//headerの高さを取得
			var headerH = $("header").outerHeight(true);

			//.scroll_pointというクラス名がついたエリアの位置を取得する設定
			$(".scroll_point").each(function(i) {//.scroll_pointクラスがついたエリアからトップまでの距離を計算して設定
				elemTop[i] =Math.round(parseInt($(this).offset().top-headerH));//追従するheader分の高さを引き小数点を四捨五入
			});
		}

		//ナビゲーションに現在地のクラスをつけるための設定
		function ScrollAnime() {//スクロールした際のナビゲーションの関数にまとめる
		var scroll = Math.round($(window).scrollTop());
		var NavElem = $("#nav ul li");//ナビゲーションのliの何番目かを定義するための準備
			$("#nav ul li").removeClass('current');//全てのナビゲーションの現在地クラスを除去
			if(scroll >= 0 && scroll < elemTop[1]) {//スクロール値が0以上 .scroll_point 1つめ（area-1）の高さ未満
				$(NavElem[0]).addClass('current');//1つめのliに現在地クラスを付与
			}
			else if(scroll >= elemTop[1] && scroll < elemTop[2]) {//.scroll_point 1つめ（area-1）以上.scroll_point 2つめ（area-2）未満
				$(NavElem[1]).addClass('current');//2つめのliに現在地クラスを付与
			} 
			else if(scroll >= elemTop[2]) {// .scroll-point 3つめ（area-3）以上
				$(NavElem[2]).addClass('current');//4つめのliに現在地クラスを付与
			} 
		}

		// 画面をスクロールをしたら動かしたい場合の記述
		$(window).scroll(function () {
		PositionCheck();/* 現在地を取得する関数を呼ぶ*/
		ScrollAnime();/* ナビゲーションに現在地のクラスをつけるための関数を呼ぶ*/
		});

		// ページが読み込まれたらすぐに動かしたい場合の記述
		$(window).on('load', function () {
			PositionCheck();/* 現在地を取得する関数を呼ぶ*/
			ScrollAnime();/* ナビゲーションに現在地のクラスをつけるための関数を呼ぶ*/
		});

		$(window).resize(function() {
		//リサイズされたときの処理
		PositionCheck()
		});
	// }
    
	
        $(window).scroll(function (){
            $('.fade_in').each(function(){
                var elemPos = jQuery(this).offset().top;
                var scroll = jQuery(window).scrollTop();
                var windowHeight = jQuery(window).height();
                if (scroll > elemPos - windowHeight + 150){
                    jQuery(this).addClass('scrollin');
                }
            });
        });
    
    
})